import React from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { CreatorItem } from './components/creator-item';
import { useRouter } from 'next/navigation';
import { ROUTES } from '@constants/routes.constants';
import { Note, Text } from '@ui/components';
import { useDrag } from './use-drag';
import faqIcon from '@assets/faq-icon.png';

import { onWheel, scrollVisibilityApiType } from './my-creators.utils';

import 'react-horizontal-scrolling-menu/dist/styles.css';
import { ICreator } from 'app/(app)/creator.typings';
import { CreatorItemLoader } from './components/creator-item-loader';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import { useAppDispatch } from '@lib/redux/store';
import { setCreatorId } from '@lib/redux/features/user';
import { setCurrentTab } from '@lib/redux/badges/badges';

interface IMyCreators {
  isLoading: boolean;
  creators: ICreator[];
}

export function MyCreators({ creators, isLoading }: IMyCreators) {
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const dispatch = useAppDispatch();

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const router = useRouter();

  const onClick = (id: string) => {
    if (dragging) {
      return;
    }
    dispatch(setCreatorId(id));
    dispatch(setCurrentTab('Badges'));
    router.push(`${ROUTES.creator}/?id=${id}&tab=Badges`);
  };

  return (
    <div className="w-full" onMouseLeave={dragStop}>
      <ScrollMenu
        onWheel={onWheel}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        scrollContainerClassName="pb-4 lg:pb-8 scrollbar-thumb-input scrollbar-thin scrollbar-rounded-xl flex gap-2 lg:gap-5 px-8 lg:px-12 xl:px-16 2xl:px-20"
        onMouseMove={handleDrag}>
        {isLoading
          ? [1, 2, 3].map((item) => <CreatorItemLoader key={item} />)
          : [
              ...creators,
              {
                id: '[LAST]',
                name: '',
                avatar: '',
                banner: '',
                fans: 0,
                badges: 0,
                notifications: 0,
              },
            ].map((creator, key) =>
              creator.id === '[LAST]' ? (
                <div
                  key={key}
                  className="h-full max-w-sm rounded-xl w-[360px]"
                  style={{
                    background:
                      'linear-gradient(90deg, #ECEDEF 0%, rgba(236, 237, 239, 0.00) 100%)',
                  }}
                />
              ) : (
                <CreatorItem
                  key={key}
                  id={creator.id}
                  name={creator.name}
                  avatar={creator.avatar}
                  banner={creator.banner}
                  fanAmount={creator.fans}
                  badgeAmount={creator.badges}
                  notifications={creator.notifications}
                  onClick={onClick}
                />
              ),
            )}
      </ScrollMenu>

      <div
        className={twMerge(
          'flex w-full items-center justify-center gap-2.5 py-4 lg:py-8',
          (isLoading || creators.length <= 1) && 'invisible',
        )}>
        <div className="flex items-center  gap-1.5">
          <div className="h-1 w-1 rounded-full bg-[#E3E5E8]" />
          <div className="h-1.5 w-1.5 rounded-full bg-[#E3E5E8]" />
          <div className="h-2 w-2 rounded-full bg-[#E3E5E8]" />
        </div>
        <Note type="m"> Scroll to view other creators</Note>
        <div className="flex items-center gap-1.5">
          <div className="h-2 w-2 rounded-full bg-[#E3E5E8]" />
          <div className="h-1.5 w-1.5 rounded-full bg-[#E3E5E8]" />
          <div className="h-1 w-1 rounded-full bg-[#E3E5E8]" />
        </div>
      </div>

      <Link
        href={ROUTES.faq}
        className="group mb-4 flex w-full items-center justify-center gap-2 xl:mb-6">
        <Text type="m" className="group-hover:text-note">
          What is KOR?
        </Text>
        <img src={faqIcon.src} />
      </Link>
    </div>
  );
}
