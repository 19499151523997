import { Avatar, Banner, Icon, Text, Title } from '@ui/components';
import { formatFansNumber } from '@ui/lib';
import Link from 'next/link';
import { ROUTES } from '@constants/routes.constants';
import { FanStats } from '@components/fan-stats';

interface ICreatorItemProps {
  id: string;
  name: string;
  avatar: string;
  banner: string;
  badgeAmount: number;
  fanAmount: number;
  notifications: number;
  onClick(id: string): void;
}

export function CreatorItem({
  id,
  name,
  avatar,
  banner,
  badgeAmount,
  fanAmount,
  notifications,
  onClick,
}: ICreatorItemProps) {
  function handleClick() {
    return onClick(id);
  }

  return (
    <div
      onClick={handleClick}
      className="relative cursor-pointer rounded-xl bg-white transition-colors w-[360px]">
      <div className="absolute bottom-0 left-0 right-0 top-0 z-50"></div>
      <Banner image={banner} type="fan" className="!h-[86px] rounded-t-xl" />
      <div className="relative h-16">
        <div className="absolute -top-8 flex w-full justify-center">
          <div className="relative">
            <Avatar src={avatar} size="l" className="border-0" />
            <div className="border-border absolute bottom-0 right-0 flex h-12 w-12 items-center justify-center rounded-full border bg-white">
              <Icon type="message" stroke={'#2D3139'} />
              {notifications ? (
                <div className="bg-primary absolute -top-1 left-10  min-w-[20px] rounded-full px-1 py-0.5 text-center">
                  <Text type="xs" className="break-normal text-white">
                    {notifications > 99 ? '99+' : notifications}
                  </Text>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center overflow-hidden px-6 pb-4 mt-4">
        <Title type="mm" className="line-clamp-2 h-20 truncate whitespace-pre-wrap text-center">
          {name}
        </Title>
      </div>
      <div className="flex justify-center gap-6 px-6 pb-12 lg:px-8">
        <FanStats type="badges" value={badgeAmount} />
        <FanStats type="fans" value={formatFansNumber(fanAmount)} />
      </div>
    </div>
  );
}
